import React from 'react';

/**
 * Renders the NotFound component.
 *
 * @returns {JSX.Element} The rendered NotFound component.
 */
const NotFound = () => {
    return (
        <div>
            <h1>404 Not Found</h1>
            <p>The page you are looking for does not exist.</p>
        </div>
    );
};

export default NotFound;
