import React from "react";
import { Routes, Route } from "react-router-dom";
import Navigationbar from "./components/navbar/navbar";
import SoraStep0 from "./pages/sora_step0/sora_step0";
import SoraStep1 from "./pages/sora_step1/sora_step1";
import SoraStep2 from "./pages/sora_step2/sora_step2";
import SoraStep3 from "./pages/sora_step3/sora_step3";
import SoraStep4 from "./pages/sora_step4/sora_step4";
import SoraStep5 from "./pages/sora_step5/sora_step5";
import SoraStep6 from "./pages/sora_step6/sora_step6";
import SoraStep7 from "./pages/sora_step7/sora_step7";
import SoraStep8 from "./pages/sora_step8/sora_step8";
import SoraStep9 from "./pages/sora_step9/sora_step9";
import SoraStart from "./pages/sora_start/sora_start";
import Homepage from "./pages/homepage/homepage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import NotFound from "./pages/not_found/not_found";
import SoraFolder from "./pages/sora_folder/sora_folder";
import PrivateRoute from "./helpers/PrivateRoute";
import keycloak from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";

/**
 * Renders the main application component.
 * @returns {JSX.Element} The rendered application component.
 */
function App() {
  return (
    <>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ onLoad: "check-sso" }}
      >
        <Navigationbar />
        <Routes>
          <Route index element={<Homepage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/sora_step0" element={<PrivateRoute><SoraStep0 /></PrivateRoute>} />
          <Route path="/sora_step1" element={<PrivateRoute><SoraStep1 /></PrivateRoute>} />
          <Route path="/sora_step2" element={<PrivateRoute><SoraStep2 /></PrivateRoute>} />
          <Route path="/sora_step3" element={<PrivateRoute><SoraStep3 /></PrivateRoute>} />
          <Route path="/sora_step4" element={<PrivateRoute><SoraStep4 /></PrivateRoute>} />
          <Route path="/sora_step5" element={<PrivateRoute><SoraStep5 /></PrivateRoute>} />
          <Route path="/sora_step6" element={<PrivateRoute><SoraStep6 /></PrivateRoute>} />
          <Route path="/sora_step7" element={<PrivateRoute><SoraStep7 /></PrivateRoute>} />
          <Route path="/sora_step8" element={<PrivateRoute><SoraStep8 /></PrivateRoute>} />
          <Route path="/sora_step9" element={<PrivateRoute><SoraStep9 /></PrivateRoute>} />
          <Route path="/sora_start" element={<PrivateRoute><SoraStart /></PrivateRoute>} />
          <Route path="/sora_folder" element={<PrivateRoute><SoraFolder /></PrivateRoute>} />
        </Routes>
      </ReactKeycloakProvider>
    </>
  );
}

export default App;
