import React from "react";
import "./navbar.css"; // Importing custom styles for the navbar component
import Container from "react-bootstrap/Container"; // Importing Container component from react-bootstrap
import Nav from "react-bootstrap/Nav"; // Importing Nav component from react-bootstrap
import Navbar from "react-bootstrap/Navbar"; // Importing Navbar component from react-bootstrap
import NavDropdown from "react-bootstrap/NavDropdown"; // Importing NavDropdown component from react-bootstrap
import { useKeycloak } from "@react-keycloak/web"; // Importing useKeycloak hook from react-keycloak/web package

/**
 * Represents the navigation bar component.
 * @returns {JSX.Element} The navigation bar component.
 */
const Navigationbar = () => {
  const { keycloak, initialized } = useKeycloak(); // Using useKeycloak hook to access Keycloak authentication state
  const authenticated = keycloak.authenticated; // Storing the authentication status from Keycloak
  
  // If the user is authenticated, store the user ID in localStorage
  if (!!authenticated) {
    localStorage.setItem("user_id", JSON.stringify(keycloak.tokenParsed.sub));
  }

  return (
    <Navbar bg="dark" data-bs-theme="dark"> {/* Bootstrap Navbar component with dark background */}
      <Container> {/* Bootstrap Container component to contain Navbar content */}
        <Navbar.Brand href="/"> {/* Branding with a link to the home page */}
          <img
            src="./logo.jpg" // Path to the logo image (it's in public)
            width="70"
            height="40"
            className="d-inline-block align-top" // Bootstrap classes for alignment
            alt="React Bootstrap logo" // Alternate text for the logo
          />
        </Navbar.Brand>
        <Nav className="me-auto"> {/* Nav component for left-aligned items */}
          <Nav.Link href="/sora_folder" id="sora-dashboard">Sora Dashboard</Nav.Link> {/* Link to the Sora Dashboard */}
          {/* Additional commented-out Nav.Links for potential future use */}
        </Nav>
        <Nav> {/* Nav component for right-aligned items */}
          <NavDropdown
            title="Account" // Title of the dropdown menu
            id="basic-nav-dropdown" // Unique ID for the dropdown
            className="hover:text-gray-200" // Custom CSS class for dropdown styling
          >
            {/* Render login button if user is not authenticated */}
            {!keycloak.authenticated && (
              <NavDropdown.Item id="login-button" onClick={() => keycloak.login()}>
                Login
              </NavDropdown.Item>
            )}
            {/* Render logout button if user is authenticated */}
            {!!keycloak.authenticated && (
              <NavDropdown.Item id="logout-button" onClick={() => keycloak.logout()}>
                Logout ({keycloak.tokenParsed.preferred_username})
              </NavDropdown.Item>
            )}
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Navigationbar;
