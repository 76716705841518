import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  InputGroup,
  Button,
  Accordion,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { FaInfoCircle } from "react-icons/fa";
import "./sora_step9.css";
import { ButtonToolbar, ButtonGroup } from "react-bootstrap";

/**
 * Represents the SoraStep9 component.
 * This component is responsible for rendering the step 9 of the SORA application.
 * It fetches data from the server and renders the sections, questions, and OZO.
 * It also handles the generation of a PDF document and OSO's score.
 */
function SoraStep9() {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [steps, setSteps] = useState([]);
  const [sections, setSections] = useState([]);
  const [osoIntegrity, setOsoIntegrity] = useState([]);
  const [osoAssurance, setOsoAssurance] = useState([]);
  const [uniqueOsoTitles, setUniqueTitles] = useState([]);
  const [calculation, setCalculation] = useState([]);
  const [tooltipStates, setTooltipStates] = useState({});
  const [tooltipStatesQuestion, setTooltipStatesQuestion] = useState({});
  const url = process.env.REACT_APP_API_URL;

  const fetchCalculation = async () => {
    const versionNumber = JSON.parse(localStorage.getItem("sora_version"));

    if (versionNumber === "2.0" || versionNumber === "2.5") {
      const endpoint =
        versionNumber === "2.0" ? "calculate2_0" : "calculate2_5";

      try {
        const response = await fetch(`${url}${endpoint}`, {
          method: "POST", // Set the HTTP method to POST
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            take_analysis_id: localStorage.getItem("sora_analysis_id"),
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setCalculation(data);
      } catch (error) {
        console.error("Error fetching take answers:", error);
        // Handle the error as needed.
      }
    }
  };

  const fetchOsoIntegrity = async () => {
    try {
      const response = await fetch(`${url}sora_integrity2_5`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setOsoIntegrity(data);
    } catch (error) {
      console.error("Error fetching steps:", error);
      // Handle the error as needed.
    }
  };

  const fetchOsoAssurance = async () => {
    try {
      const response = await fetch(`${url}sora_assurance2_5`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setOsoAssurance(data);
      data.forEach((item) => {
        if (!uniqueOsoTitles.includes(item.oso_title)) {
          uniqueOsoTitles.push(item.oso_title);
        }
      });
    } catch (error) {
      console.error("Error fetching steps:", error);
      // Handle the error as needed.
    }
  };

  const fetchQuestions = async () => {
    try {
      const response = await fetch(`${url}sora_questions`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setQuestions(data);
    } catch (error) {
      console.error("Error fetching questions:", error);
      // Handle the error as needed.
    }
  };

  const fetchSteps = async () => {
    try {
      const response = await fetch(`${url}sora_steps`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setSteps(data);
    } catch (error) {
      console.error("Error fetching steps:", error);
      // Handle the error as needed.
    }
  };

  const fetchSections = async () => {
    try {
      const response = await fetch(`${url}sora_sections`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error("Error fetching steps:", error);
      // Handle the error as needed.
    }
  };

  useEffect(() => {
    fetchOsoAssurance();
    fetchOsoIntegrity();
    fetchQuestions();
    fetchSteps();
    fetchSections();
    fetchCalculation();
  }, []);

  /**
   * Generates a PDF file based on the selected SORA version and analysis ID.
   * @async
   * @function generatePDF
   * @returns {Promise<void>} A Promise that resolves when the PDF generation is complete.
   */
  async function generatePDF() {
    const sora_version = JSON.parse(localStorage.getItem("sora_version"));
    const analysis_id = JSON.parse(localStorage.getItem("sora_analysis_id"));
    let version = "";
    if (sora_version === "2.0") {
      version = "2_0";
    }
    if (sora_version === "2.5") {
      version = "2_5";
    }
    try {
      const response = await fetch(`${url}generate-pdf${version}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sora_analysis_id: analysis_id,
          user_id: JSON.parse(localStorage.getItem("user_id")),
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const pdfBlob = await response.blob();

      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(pdfBlob);
      downloadLink.download = "SORA.pdf";
      downloadLink.click();
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }

  function renderSectionsForStep(step) {
    const stepSections = sections.filter(
      (section) => section.step_id === step.id
    );

    const handleToggleTooltip = (sectionId) => {
      setTooltipStates((prevState) => ({
        ...prevState,
        [sectionId]: !prevState[sectionId] || false,
      }));
    };

    return stepSections.map((section) => {
      const showTooltip = tooltipStates[section.id] || false;

      return (
        <div key={section.id}>
          <h2>
            {section.section}
            <FaInfoCircle
              className="info-icon"
              onClick={() => handleToggleTooltip(section.id)}
            />
          </h2>
          {showTooltip && (
            <span className="tooltip-text">
              Additional information: <br />
              {section.explaination}
            </span>
          )}
          <br></br>
          {renderQuestionsForStep(section)}
        </div>
      );
    });
  }

  function renderQuestionsForStep(section) {
    const stepQuestions = questions.filter(
      (question) => question.section_id === section.id
    );

    const handleToggleTooltip = (questionId) => {
      setTooltipStatesQuestion((prevState) => ({
        ...prevState,
        [questionId]: !prevState[questionId] || false,
      }));
    };

    return stepQuestions.map((question) => {
      const showTooltip = tooltipStatesQuestion[question.id] || false;

      return (
        <div key={question.id}>
          <h5>
            {question.content}
            <FaInfoCircle
              className="info-icon"
              onClick={() => handleToggleTooltip(question.id)}
            />
          </h5>
          {showTooltip && (
            <span className="tooltip-text">
              Additional information: <br />
              {question.explaination}
            </span>
          )}
          {renderOptions(question.id)}
          {renderQuestionType(question)}
          <br></br>
        </div>
      );
    });
  }

  function renderOptions(questionId) {
    const questionOptions = answers.filter(
      (answer) => answer.question_id === questionId
    );
    return (
      <Form.Group key={questionId}>
        {questionOptions.map((option) => (
          <Form.Check type="checkbox" label={option.content} key={option.id} />
        ))}
      </Form.Group>
    );
  }

  function renderQuestionType(question) {
    if (question.type === "open") {
      return (
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label></Form.Label>
            <Form.Control as="textarea" rows={1} />
          </Form.Group>
        </Form>
      );
    } else if (question.type === "y/n") {
      return (
        <Form>
          <Form.Check type="checkbox" id={`1`} label={`yes`} />
          <Form.Check type="checkbox" label={`no`} id={`2`} />
        </Form>
      );
    } else if (question.type === "openM") {
      return (
        <InputGroup className="mb-3">
          <Form.Control aria-label="" aria-describedby="basic-addon2" />
          <InputGroup.Text id="basic-addon2">{question.metric}</InputGroup.Text>
        </InputGroup>
      );
    }
  }

  /**
   * Color codes the SAIL based on the provided title.
   * @param {string} title - The title of the SAIL.
   * @returns {JSX.Element|null} - The colored SAIL component or null if no match found.
   */
  function colorCodeSAIL(title) {
    if (typeof calculation.OSO_ASSURANCE === "string") {
      return;
    } else if (Array.isArray(calculation.OSO_ASSURANCE)) {
      for (let i = 0; i < calculation.OSO_ASSURANCE.length; i++) {
        if (calculation.OSO_ASSURANCE[i].oso_title === title) {
          if (calculation.OSO_ASSURANCE[i].level === "low") {
            return (
              <div style={{ textAlign: "right" }}>
                <p style={{ color: "green" }}>LOW</p>
              </div>
            );
          } else if (calculation.OSO_ASSURANCE[i].level === "medium") {
            return (
              <div style={{ textAlign: "right" }}>
                <p style={{ color: "orange" }}>MEDIUM</p>
              </div>
            );
          } else if (calculation.OSO_ASSURANCE[i].level === "high") {
            return (
              <div style={{ textAlign: "right" }}>
                <p style={{ color: "red" }}>HIGH</p>
              </div>
            );
          }
        }
      }
    } else {
      return;
    }
  }

  /**
   * Renders the accordion items based on the uniqueOsoTitles array.
   * Each accordion item contains tabs for different levels of integrity and assurance.
   * @returns {Array} An array of accordion items.
   */
  function renderAccordion() {
    const accordionItems = [];
    for (let i = 0; i < uniqueOsoTitles.length; i++) {
      accordionItems.push(
        <Accordion.Item eventKey={i}>
          <Accordion.Header
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "fit-content",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "95%",
                height: "fit-content",
              }}
            >
              <span>{uniqueOsoTitles[i]}</span>
              {colorCodeSAIL(uniqueOsoTitles[i])}
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Tabs
              defaultActiveKey="low"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="low" title="Low">
                <h3>Integrity</h3>
                {osoIntegrity.map((integrity) => {
                  if (
                    integrity.oso_title === uniqueOsoTitles[i] &&
                    integrity.level === "low"
                  ) {
                    return (
                      <>
                        <h5>{integrity.integrity_header}</h5>
                        <p>{integrity.integrity}</p>
                      </>
                    );
                  }
                  return null;
                })}
                <h3>Assurance</h3>
                {osoAssurance.map((assurance) => {
                  if (
                    assurance.oso_title === uniqueOsoTitles[i] &&
                    assurance.level === "low"
                  ) {
                    return (
                      <>
                        <h5>{assurance.assurance_header}</h5>
                        <p>{assurance.assurance}</p>
                      </>
                    );
                  }
                  return null;
                })}
              </Tab>
              <Tab eventKey="medium" title="Medium">
                <h3>Integrity</h3>
                {osoIntegrity.map((integrity) => {
                  if (
                    integrity.oso_title === uniqueOsoTitles[i] &&
                    integrity.level === "medium"
                  ) {
                    return (
                      <>
                        <h5>{integrity.integrity_header}</h5>
                        <p>{integrity.integrity}</p>
                      </>
                    );
                  }
                  return null;
                })}
                <h3>Assurance</h3>
                {osoAssurance.map((assurance) => {
                  if (
                    assurance.oso_title === uniqueOsoTitles[i] &&
                    assurance.level === "medium"
                  ) {
                    return (
                      <>
                        <h5>{assurance.assurance_header}</h5>
                        <p>{assurance.assurance}</p>
                      </>
                    );
                  }
                  return null;
                })}
              </Tab>
              <Tab eventKey="high" title="High">
                <h3>Integrity</h3>
                {osoIntegrity.map((integrity) => {
                  if (
                    integrity.oso_title === uniqueOsoTitles[i] &&
                    integrity.level === "high"
                  ) {
                    return (
                      <>
                        <h5>{integrity.integrity_header}</h5>
                        <p>{integrity.integrity}</p>
                      </>
                    );
                  }
                  return null;
                })}
                <h3>Assurance</h3>
                {osoAssurance.map((assurance) => {
                  if (
                    assurance.oso_title === uniqueOsoTitles[i] &&
                    assurance.level === "high"
                  ) {
                    return (
                      <>
                        <h5>{assurance.assurance_header}</h5>
                        <p>{assurance.assurance}</p>
                      </>
                    );
                  }
                  return null;
                })}
              </Tab>
            </Tabs>
          </Accordion.Body>
        </Accordion.Item>
      );
    }

    return accordionItems;
  }

  const navigate = useNavigate();

  const handlePrevious = () => {
    navigate("/sora_step8");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNext = () => {
    generatePDF();
  };

  const handleStep0 = () => {
    navigate("/sora_step0");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep1 = () => {
    navigate("/sora_step1");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep2 = () => {
    navigate("/sora_step2");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep3 = () => {
    navigate("/sora_step3");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep4 = () => {
    navigate("/sora_step4");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep5 = () => {
    navigate("/sora_step5");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep6 = () => {
    navigate("/sora_step6");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep7 = () => {
    navigate("/sora_step7");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep8 = () => {
    navigate("/sora_step8");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep9 = () => {
    navigate("/sora_step9");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Container style={{ height: "100%" ,paddingBottom: "100px", minHeight: "100vh"  }}>
      <div className="homepage">
        <br></br>
        {steps
          .filter((step) => step.GUIorder === 10)
          .map((step) => (
            <div key={step.id}>
              <h1>{step.step}</h1>
              <br></br>
              {renderSectionsForStep(step)}
            </div>
          ))}
        <Accordion defaultActiveKey="1">{renderAccordion()}</Accordion>
        <br></br>
        <br></br>
      </div>
      <Navbar expand="lg" className="bg-body-tertiary" sticky="bottom" style={{ position: "fixed", bottom: 0}}>
        <Container>
          <div className="d-flex flex-column">
            {" "}
            {/* Vertical layout container */}
            <Navbar.Brand href="#">
              GRC = {calculation.GRC}, MRC= {calculation.MGR}
              <br></br> FINAL_GRC=
              {calculation.FINAL_GRC} ARC={calculation.ARC}, SAIL={" "}
              {calculation.SAIL}
            </Navbar.Brand>
            <ButtonToolbar
              aria-label="Toolbar with button groups"
              className="mt-auto"
            >
              <ButtonGroup className="me-2" aria-label="First group">
                <Button variant="primary" onClick={handlePrevious}>
                  Go back
                </Button>
              </ButtonGroup>
              <ButtonGroup className="me-2" aria-label="Second group">
                <Button
                  id="nav-step0"
                  variant="secondary"
                  onClick={handleStep0}
                >
                  Step 0
                </Button>
                <Button
                  id="nav-step1"
                  variant="secondary"
                  onClick={handleStep1}
                >
                  Step 1
                </Button>
                <Button
                  id="nav-step2"
                  variant="secondary"
                  onClick={handleStep2}
                >
                  Step 2
                </Button>
                <Button
                  id="nav-step3"
                  variant="secondary"
                  onClick={handleStep3}
                >
                  Step 3
                </Button>
                <Button
                  id="nav-step4"
                  variant="secondary"
                  onClick={handleStep4}
                >
                  Step 4
                </Button>
                <Button
                  id="nav-step5"
                  variant="secondary"
                  onClick={handleStep5}
                >
                  Step 5
                </Button>
                <Button
                  id="nav-step6"
                  variant="secondary"
                  onClick={handleStep6}
                >
                  Step 6
                </Button>
                <Button
                  id="nav-step7"
                  variant="secondary"
                  onClick={handleStep7}
                >
                  Step 7
                </Button>
                <Button
                  id="nav-step8"
                  variant="secondary"
                  onClick={handleStep8}
                >
                  Step 8
                </Button>
                <Button id="nav-step9" variant="primary" onClick={handleStep9}>
                  Step 9
                </Button>
              </ButtonGroup>
              <ButtonGroup className="me-2" aria-label="Third group">
                <Button variant="primary" onClick={handleNext}>
                  Print Sora
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </div>
        </Container>
      </Navbar>
    </Container>
  );
}

export default SoraStep9;
