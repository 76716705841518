import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./sora_start.css";

/**
 * Represents a survey component.
 * @returns {JSX.Element} The rendered survey component.
 */
const Survey = () => {
  const [sora2_0, setSora2_0] = useState([]);
  const [sora2_5, setSora2_5] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL;
  const [analysisName2_0, setAnalysisName2_0] = useState("");
  const [analysisName2_5, setAnalysisName2_5] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [folderName, setFolderName] = useState([]);

  /**
   * Fetches SORA data from the server.
   * @param {string} version - The version of SORA data to fetch.
   * @param {Function} setData - The function to set the fetched data.
   * @returns {Promise<void>} - A promise that resolves when the data is fetched and set.
   */
  const fetchSoraData = async (version, setData) => {
    try {
      const response = await fetch(`${url}sora_analysis${version}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setData(data);
    } catch (err) {
      console.error(`Error fetching SORA choices:`, err);
      setError(`Error fetching SORA choices`);
    }
  };

  /**
   * Fetches the folder for the current user.
   * @returns {Promise<void>} A promise that resolves when the folder is fetched successfully.
   */
  const fetchFolder = async () => {
    try {
      const response = await fetch(
        `${url}get_folder_user?user_id=${JSON.parse(
          localStorage.getItem("user_id")
        )}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setFolderName(data);
    } catch (err) {
      console.error(`Error folder options`, err);
      setError(`Error fetching folder options`);
    }
  };

  /**
   * Fetches folder organisation data from the server.
   * @returns {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fetchFolderOrganisation = async () => {
    try {
      const response = await fetch(
        `${url}get_folder_organisation?organisation_id=${JSON.parse(
          localStorage.getItem("organisation_id")
        )}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setFolderName(data);
    } catch (err) {
      console.error(`Error fetching folder options for organisation`, err);
      setError(`Error fetching folder options for organisation`);
    }
  };

  useEffect(() => {
    // This effect runs when the component mounts, and only once due to the empty dependency array ([]).

    // Fetch Sora data for version 2.0 from the server and update the state using setSora2_0 function.
    fetchSoraData("2_0", setSora2_0);

    // Fetch Sora data for version 2.5 from the server and update the state using setSora2_5 function.
    fetchSoraData("2_5", setSora2_5);

    // Check if the "organisation_id" key in localStorage is equal to 0.
    if (
      JSON.parse(localStorage.getItem("organisation_id")) === 0 ||
      JSON.parse(localStorage.getItem("organisation_id")) === null
    ) {
      // If the condition is true, fetch folders.
      fetchFolder();
    } else {
      // If the condition is false, fetch folders for a specific organisation.
      fetchFolderOrganisation();
    }
  }, []);

  /**
   * Stores the analysis data and creates a new analysis in the database.
   * @param {Array} data - The analysis data.
   * @param {string} analysis_name - The name of the analysis.
   * @returns {Promise<void>} - A promise that resolves when the analysis is stored successfully.
   */
  const storeAnalysis = async (data, analysis_name) => {
    try {
      const response = await fetch(`${url}create_analysis_id`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          analysis_id: data[0].id,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();
      const insertedId = parseInt(res.insertedId);
      localStorage.setItem("sora_analysis_id", JSON.stringify(insertedId));
    } catch (err) {
      console.error(`Error creating analysis_id:`, err);
      setError(`Error creating analysis_id`);
    }
    if (
      JSON.parse(localStorage.getItem("organisation_id")) === 0 ||
      JSON.parse(localStorage.getItem("organisation_id")) === null
    ) {
      try {
        const response = await fetch(`${url}create_sora`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: analysis_name,
            user_id: JSON.parse(localStorage.getItem("user_id")),
            sora_analysis_id: JSON.parse(
              localStorage.getItem("sora_analysis_id")
            ),
            parent_id: selectedFolder.id,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const analysis = await response.json();
      } catch (err) {
        console.error(`Error saving sora_analysis:`, err);
        setError(`Error saving sora_analysis`);
      }
    } else {
      try {
        const response = await fetch(`${url}create_sora_organisation`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: analysis_name,
            organisation_id: JSON.parse(
              localStorage.getItem("organisation_id")
            ),
            sora_analysis_id: JSON.parse(
              localStorage.getItem("sora_analysis_id")
            ),
            parent_id: selectedFolder.id,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const analysis = await response.json();
      } catch (err) {
        console.error(`Error saving sora_analysis:`, err);
        setError(`Error saving sora_analysis`);
      }
    }
  };

  /**
   * Handles the start action.
   * @param {Array} data - The data to be processed.
   */
  const handleStart = (data) => {
    if (data.length > 0) {
      if (data[0].sora_version === "2.0") {
        if (analysisName2_0 === "" || analysisName2_0.trim() === ""){
          alert("Please enter analysis name for sora2.0");
          return;
        } else {
          localStorage.setItem("sora_version", JSON.stringify("2.0"));
          storeAnalysis(data, analysisName2_0)
            .then(() => navigate("/sora_step1"))
            .catch((error) => {
              // Handle error if storeAnalysis fails
              console.error("Error storing analysis:", error);
            });
        }
      }
      if (data[0].sora_version === "2.5") {
        if (analysisName2_5 === "" || analysisName2_0.trim() === "") {
          alert("Please enter analysis name for sora2.5");
          return;
        } else {
          localStorage.setItem("sora_version", JSON.stringify("2.5"));
          storeAnalysis(data, analysisName2_5)
            .then(() => navigate("/sora_step1"))
            .catch((error) => {
              // Handle error if storeAnalysis fails
              console.error("Error storing analysis:", error);
            });
        }
      }
    }
  };

  return (
    <Container>
      {/* Adds some space */}
      <br />

      {/* Displays a heading */}
      <h1 className="center">Choose Sora Version</h1>

      {/* Adds some space */}
      <br />

      {/* Renders a row */}
      <Row>
        {/* Checks if there's an error */}
        {error ? (
          // If there's an error, displays it
          <Col>
            <p>{error}</p>
          </Col>
        ) : (
          // If there's no error, renders Sora data
          <>
            {/* Maps over Sora data for version 2.0 */}
            {sora2_0.map((soraData, index) => (
              <Col key={index}>
                {/* Displays Sora title */}
                <h1>{soraData.title}</h1>
                {/* Displays Sora summary */}
                <p>{soraData.summary}</p>

                {/* Renders a form */}
                <Form>
                  {/* Input for Sora analysis name */}
                  <Form.Group className="mb-3" controlId="start-name2_0">
                    <Form.Label>Sora analysis name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="start type sora2.0..."
                      value={analysisName2_0}
                      onChange={(e) => setAnalysisName2_0(e.target.value)}
                    />
                  </Form.Group>

                  {/* Select dropdown for folder selection */}
                  <Form.Group controlId="start-select2_0">
                    <Form.Label>Select an item:</Form.Label>
                    <Form.Select
                      onChange={(e) =>
                        setSelectedFolder(
                          folderName.find(
                            (item) => item.name === e.target.value
                          ) || {}
                        )
                      }
                      value={selectedFolder ? selectedFolder.name : ""}
                    >
                      {/* Placeholder option */}
                      <option value="none">Select a folder</option>
                      {/* Option for no folder selected */}
                      <option key="0" value="None">
                        None
                      </option>

                      {/* Maps over folder names */}
                      {folderName.map((item) => {
                        // Filters out non-folder items
                        if (item.type === "folder") {
                          return (
                            // Renders folder options
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          );
                        } else {
                          // Returns null for non-folder items
                          return null;
                        }
                      })}
                    </Form.Select>
                  </Form.Group>
                </Form>

                {/* Button to start analysis */}
                <Button
                  id="start2_0"
                  variant="primary"
                  onClick={() => handleStart(sora2_0)}
                >
                  Start
                </Button>
              </Col>
            ))}

            {/* Maps over Sora data for version 2.5 */}
            {sora2_5.map((soraData, index) => (
              <Col key={index}>
                {/* Displays Sora title */}
                <h1>{soraData.title}</h1>
                {/* Displays Sora summary */}
                <p>{soraData.summary}</p>

                {/* Renders a form */}
                <Form>
                  {/* Input for Sora analysis name */}
                  <Form.Group className="mb-3" controlId="start-name2_5">
                    <Form.Label>Sora analysis name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="start type sora2.5..."
                      value={analysisName2_5}
                      onChange={(e) => setAnalysisName2_5(e.target.value)}
                    />
                  </Form.Group>

                  {/* Select dropdown for folder selection */}
                  <Form.Group controlId="start-select2_5">
                    <Form.Label>Select an item:</Form.Label>
                    <Form.Select
                      onChange={(e) =>
                        setSelectedFolder(
                          folderName.find(
                            (item) => item.name === e.target.value
                          ) || {}
                        )
                      }
                      value={selectedFolder ? selectedFolder.name : ""}
                    >
                      {/* Placeholder option */}
                      <option value="">Select a folder</option>
                      {/* Option for no folder selected */}
                      <option key="0" value="none">
                        None
                      </option>

                      {/* Maps over folder names */}
                      {folderName.map((item) => {
                        // Filters out non-folder items
                        if (item.type === "folder") {
                          return (
                            // Renders folder options
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          );
                        } else {
                          // Returns null for non-folder items
                          return null;
                        }
                      })}
                    </Form.Select>
                  </Form.Group>
                </Form>

                {/* Button to start analysis */}
                <Button
                  id="start2_5"
                  variant="primary"
                  onClick={() => handleStart(sora2_5)}
                >
                  Start
                </Button>
              </Col>
            ))}
          </>
        )}
      </Row>

      {/* Empty div */}
      <div></div>
    </Container>
  );
};

export default Survey;
