// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-primary {
    background-color: #007bff;
    border: none;
  }
  
.btn-primary:hover {
    background-color: #0056b3;
  }

.center {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/sora_start/sora_start.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;EACd;;AAEF;IACI,yBAAyB;EAC3B;;AAEF;EACE,kBAAkB;AACpB","sourcesContent":[".btn-primary {\n    background-color: #007bff;\n    border: none;\n  }\n  \n.btn-primary:hover {\n    background-color: #0056b3;\n  }\n\n.center {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
