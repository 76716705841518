// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `Navigationbar.css
.nav-link {
    position: relative;
    padding: 10px 15px;
    text-decoration: none;
    color: white;
    transition: background-color 0.3s ease-in-out;
    transition: all 2s;
}

.nav-link:hover {
    background-color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
    color: #fff;
    z-index: 1;
}

.nav-link:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #aaa;
    height: 1px;
}

.nav-link:hover:after {
    width: 100%;
    z-index: -10;
    animation: fill 1s forwards;
    -webkit-animation: fill 1s forwards;
    -moz-animation: fill 1s forwards;
    opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,6CAA6C;IAC7C,kBAAkB;AACtB;;AAEA;IACI,0CAA0C;IAC1C,qBAAqB;IACrB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,SAAS;IACT,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,mCAAmC;IACnC,gCAAgC;IAChC,UAAU;AACd","sourcesContent":["Navigationbar.css\n.nav-link {\n    position: relative;\n    padding: 10px 15px;\n    text-decoration: none;\n    color: white;\n    transition: background-color 0.3s ease-in-out;\n    transition: all 2s;\n}\n\n.nav-link:hover {\n    background-color: rgba(255, 255, 255, 0.2);\n    text-decoration: none;\n    color: #fff;\n    z-index: 1;\n}\n\n.nav-link:after {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    margin: auto;\n    width: 0%;\n    content: '.';\n    color: transparent;\n    background: #aaa;\n    height: 1px;\n}\n\n.nav-link:hover:after {\n    width: 100%;\n    z-index: -10;\n    animation: fill 1s forwards;\n    -webkit-animation: fill 1s forwards;\n    -moz-animation: fill 1s forwards;\n    opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
