import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import {
  Container,
  Form,
  InputGroup,
  Button,
  ButtonGroup,
  ButtonToolbar,
} from "react-bootstrap";
import context from "react-bootstrap/esm/AccordionContext";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import Navbar from "react-bootstrap/Navbar";

//note: this was to fix the queries update stackup and send to backend when user type in inputfield but was not needed.
const debouncedSubmit = debounce(submitFormData, 1);
const url = process.env.REACT_APP_API_URL;

async function submitFormData(updatedData) {
  try {
    // Send a POST request to your server API endpoint
    await fetch(`${url}take_answers`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    });

    // Optionally, you can reset the form data after submission
  } catch (error) {
    console.error("Error submitting data:", error);
    // Handle the error as needed.
  }
}

/**
 * Represents the Sora Step 7 component.
 * This component is responsible for rendering the 7 step of the Sora form,
 * including fetching questions, answers, steps, sections, and take answers from the server,
 * handling user input, and navigating to other steps.
 */
function SoraStep7() {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [steps, setSteps] = useState([]);
  const [sections, setSections] = useState([]);
  const [formData, setFormData] = useState([]);
  const [takeData, setTakeData] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [calculation, setCalculation] = useState([]);
  const [tooltipStates, setTooltipStates] = useState({});
  const [tooltipStatesQuestion, setTooltipStatesQuestion] = useState({});
  const navigate = useNavigate();

  const fetchCalculation = async () => {
    const versionNumber = JSON.parse(localStorage.getItem("sora_version"));

    if (versionNumber === "2.0" || versionNumber === "2.5") {
      const endpoint =
        versionNumber === "2.0" ? "calculate2_0" : "calculate2_5";

      try {
        const response = await fetch(`${url}${endpoint}`, {
          method: "POST", // Set the HTTP method to POST
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            take_analysis_id: localStorage.getItem("sora_analysis_id"),
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setCalculation(data);
      } catch (error) {
        console.error("Error fetching take answers:", error);
        // Handle the error as needed.
      }
    }
  };

  const fetchQuestions = async () => {
    const versionNumber = JSON.parse(localStorage.getItem("sora_version"));
    try {
      const response = await fetch(
        `${url}sora_questions_by_version?version=${versionNumber}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setQuestions(data);
    } catch (error) {
      console.error("Error fetching questions:", error);
      // Handle the error as needed.
    }
  };

  const fetchAnswers = async () => {
    try {
      const response = await fetch(`${url}sora_answers`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setAnswers(data);
    } catch (error) {
      console.error("Error fetching answers:", error);
      // Handle the error as needed.
    }
  };

  const fetchSteps = async () => {
    try {
      const response = await fetch(`${url}sora_steps`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setSteps(data);
    } catch (error) {
      console.error("Error fetching steps:", error);
      // Handle the error as needed.
    }
  };

  const fetchSections = async () => {
    try {
      const response = await fetch(`${url}sora_sections`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error("Error fetching steps:", error);
      // Handle the error as needed.
    }
  };

  const fetchTakeAnswer = async () => {
    let takeAnalysisId = JSON.parse(localStorage.getItem("sora_analysis_id"));
    try {
      const response = await fetch(
        `${url}take_answers?take_analysis_id=${takeAnalysisId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setTakeData(data);
    } catch (error) {
      console.error("Error fetching take answers:", error);
      // Handle the error as needed.
    }
  };

  useEffect(() => {
    fetchQuestions();
    fetchAnswers();
    fetchSteps();
    fetchSections();
    fetchTakeAnswer();
    fetchCalculation();
  }, []);

  const handleInputChange = (questionId, value) => {
    setTakeData((prevData) => {
      const existingEntryIndex = prevData.findIndex(
        (entry) => entry.question_id === questionId
      );

      const updatedData = [...prevData];

      if (existingEntryIndex !== -1) {
        // If an entry exists, update its content
        updatedData[existingEntryIndex] = {
          ...updatedData[existingEntryIndex],
          content: value,
        };
      } else {
        // If no entry exists, add a new entry
        updatedData.push({
          user_id: JSON.parse(localStorage.getItem("user_id")),
          take_analysis_id: JSON.parse(
            localStorage.getItem("sora_analysis_id")
          ),
          question_id: questionId,
          content: value,
        });
      }
      // Submit the updated data after a delay using debounce
      debouncedSubmit(updatedData);

      return updatedData;
    });
  };

  function renderSectionsForStep(step) {
    const stepSections = sections.filter(
      (section) => section.step_id === step.id
    );

    const handleToggleTooltip = (sectionId) => {
      setTooltipStates((prevState) => ({
        ...prevState,
        [sectionId]: !prevState[sectionId] || false,
      }));
    };

    return stepSections.map((section) => {
      const showTooltip = tooltipStates[section.id] || false;

      return (
        <div key={section.id}>
          <h2>
            {section.section}
            <FaInfoCircle
              className="info-icon"
              onClick={() => handleToggleTooltip(section.id)}
            />
          </h2>
          {showTooltip && (
            <span className="tooltip-text">
              Additional information: <br />
              {section.explaination}
            </span>
          )}
          <br></br>
          {renderQuestionsForStep(section)}
        </div>
      );
    });
  }

  function renderQuestionsForStep(section) {
    const stepQuestions = questions.filter(
      (question) => question.section_id === section.id
    );

    const handleToggleTooltip = (questionId) => {
      setTooltipStatesQuestion((prevState) => ({
        ...prevState,
        [questionId]: !prevState[questionId] || false,
      }));
    };

    return stepQuestions.map((question) => {
      const showTooltip = tooltipStatesQuestion[question.id] || false;

      return (
        <div key={question.id}>
          <h5>
            {question.content}
            <FaInfoCircle
              className="info-icon"
              onClick={() => handleToggleTooltip(question.id)}
            />
          </h5>
          {showTooltip && (
            <span className="tooltip-text">
              Additional information: <br />
              {question.explaination}
            </span>
          )}
          {renderOptions(question.id)}
          {renderQuestionType(question)}
          <br></br>
        </div>
      );
    });
  }

  function renderOptions(questionId) {
    const questionOptions = answers.filter(
      (answer) => answer.question_id === questionId
    );
    return (
      <Form.Group key={questionId}>
        {questionOptions.map((option) => (
          <Form.Check
            type="checkbox"
            question_id={questionId}
            value={option.content}
            label={option.content}
            key={option.id}
            checked={
              option.content ===
              takeData.find((entry) => entry.question_id === questionId)
                ?.content
            }
            onChange={(e) => handleInputChange(questionId, option.content)} // Add onChange event handler
          />
        ))}
      </Form.Group>
    );
  }

  function getContextByQuestionId(formData, questionId) {
    for (let i = 0; i < formData.length; i++) {
      if (formData[i].question_id === questionId) {
        return formData[i].content;
      }
    }
    return null;
  }

  function renderQuestionType(question) {
    const contentValue = getContextByQuestionId(takeData, question.id);
    if (question.type === "open") {
      return (
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>{question.label}</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              placeholder="Start typing here..."
              value={contentValue !== null ? contentValue : ""}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
            />
          </Form.Group>
        </Form>
      );
    } else if (question.type === "y/n") {
      const contentValue =
        takeData.find((entry) => entry.question_id === question.id)?.content ||
        null;

      return (
        <Form>
          <Form.Check
            type="checkbox"
            id={`1`}
            label={`yes`}
            checked={contentValue === "yes"}
            onChange={(e) =>
              handleInputChange(question.id, e.target.checked ? "yes" : null)
            }
          />
          <Form.Check
            type="checkbox"
            label={`no`}
            id={`2`}
            checked={contentValue === "no"}
            onChange={(e) =>
              handleInputChange(question.id, e.target.checked ? "no" : null)
            }
          />
        </Form>
      );
    } else if (question.type === "openM") {
      return (
        <InputGroup className="mb-3">
          <Form.Control
            aria-label=""
            aria-describedby="basic-addon2"
            placeholder="Start typing here..."
            value={
              takeData.find((entry) => entry.question_id === question.id)
                ?.content || ""
            }
            onChange={(e) => handleInputChange(question.id, e.target.value)}
          />
          <InputGroup.Text id="basic-addon2">{question.metric}</InputGroup.Text>
        </InputGroup>
      );
    } else if (question.type === "open5") {
      return (
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>{question.label}</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Start typing here..."
              value={contentValue !== null ? contentValue : ""}
              onChange={(e) => handleInputChange(question.id, e.target.value)}
            />
          </Form.Group>
        </Form>
      );
    }

    // For other types, you can add more conditions as needed

    return null; // Return null for unsupported question types
  }

  const handlePrevious = () => {
    navigate("/sora_step6");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleNext = () => {
    navigate("/sora_step8");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep0 = () => {
    navigate("/sora_step0");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep1 = () => {
    navigate("/sora_step1");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep2 = () => {
    navigate("/sora_step2");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep3 = () => {
    navigate("/sora_step3");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep4 = () => {
    navigate("/sora_step4");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep5 = () => {
    navigate("/sora_step5");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep6 = () => {
    navigate("/sora_step6");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep7 = () => {
    navigate("/sora_step7");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep8 = () => {
    navigate("/sora_step8");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleStep9 = () => {
    navigate("/sora_step9");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Container style={{ height: "100%" ,paddingBottom: "100px", minHeight: "100vh"  }}>
      <div className="homepage">
        <br></br>
        {steps
          .filter((step) => step.GUIorder === 8)
          .map((step) => (
            <div key={step.id}>
              <h1>{step.step}</h1>
              <br></br>
              {renderSectionsForStep(step)}
            </div>
          ))}
      </div>
      <div>
        <br></br>
      </div>
      <br></br>
      <Navbar expand="lg" className="bg-body-tertiary" sticky="bottom" style={{ position: "fixed", bottom: 0}}>
        <Container>
          <div className="d-flex flex-column">
            {" "}
            {/* Vertical layout container */}
            <Navbar.Brand href="#">
              GRC = {calculation.GRC}, MRC= {calculation.MGR}
              <br></br> FINAL_GRC=
              {calculation.FINAL_GRC} ARC={calculation.ARC}, SAIL={" "}
              {calculation.SAIL}
            </Navbar.Brand>
            <ButtonToolbar
              aria-label="Toolbar with button groups"
              className="mt-auto"
            >
              <ButtonGroup className="me-2" aria-label="First group">
                <Button variant="primary" onClick={handlePrevious}>
                  Go back
                </Button>
              </ButtonGroup>
              <ButtonGroup className="me-2" aria-label="Second group">
                <Button
                  id="nav-step0"
                  variant="secondary"
                  onClick={handleStep0}
                >
                  Step 0
                </Button>
                <Button id="nav-step1" variant="secondary" onClick={handleStep1}>
                  Step 1
                </Button>
                <Button
                  id="nav-step2"
                  variant="secondary"
                  onClick={handleStep2}
                >
                  Step 2
                </Button>
                <Button
                  id="nav-step3"
                  variant="secondary"
                  onClick={handleStep3}
                >
                  Step 3
                </Button>
                <Button
                  id="nav-step4"
                  variant="secondary"
                  onClick={handleStep4}
                >
                  Step 4
                </Button>
                <Button
                  id="nav-step5"
                  variant="secondary"
                  onClick={handleStep5}
                >
                  Step 5
                </Button>
                <Button
                  id="nav-step6"
                  variant="secondary"
                  onClick={handleStep6}
                >
                  Step 6
                </Button>
                <Button
                  id="nav-step7"
                  variant="primary"
                  onClick={handleStep7}
                >
                  Step 7
                </Button>
                <Button
                  id="nav-step8"
                  variant="secondary"
                  onClick={handleStep8}
                >
                  Step 8
                </Button>
                <Button
                  id="nav-step9"
                  variant="secondary"
                  onClick={handleStep9}
                >
                  Step 9
                </Button>
              </ButtonGroup>
              <ButtonGroup className="me-2" aria-label="Third group">
                <Button variant="primary" onClick={handleNext}>
                  Next
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </div>
        </Container>
      </Navbar>
    </Container>
  );
}

export default SoraStep7;
