import React from 'react';
import './homepage.css';
import { Container } from 'react-bootstrap';

const Homepage = () => (
  <Container>
  <div className='homepage'>
    <h1>Welkom op de website om uw SORA te berekenen voor uw vlucht.</h1>
    <br></br>
    <br></br>
    <p>Aan de hand van een aantal vragen verzamelen we informatie om uw SORA te berekenen. De SORA kunt u gebruiken om maatregelen in uw operationeel manual op te nemen en samen met een ConOps een uitzondering voor uw operatie bij de ILT aan te vragen.</p>
    <p>De SORA tool is tijdelijk een gratis dienst aangeboden door Holding The Drones en in Beta fase. Graag ontvangen we feedback van uw bevindingen op dronesora at <a href={`mailto:${"dronesora@utmeurope.com"}`}>dronesora@utmeurope.com</a></p>
    <p>Wilt u gebruik maken van de SORA tool, maakt u dan een account aan en log daarna in met dat account. U kunt per account nu nog maar één SORA invullen. Wilt u meerdere SORA’s invullen, maakt u dan meerdere account aan.</p>
    </div>
  </Container>
);

export default Homepage;
