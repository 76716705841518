import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "./sora_folder.css";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";

import { useTheme } from "@table-library/react-table-library/theme";

import {
  useSort,
  HeaderCellSort,
} from "@table-library/react-table-library/sort";

import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

import { useRowSelect } from "@table-library/react-table-library/select";

import { useTree, CellTree } from "@table-library/react-table-library/tree";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import { Button, Container, Form } from "react-bootstrap";

const THEME = {
  Table: ` --data-table-library_grid-template-columns:  minmax(200px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(100px, 1fr) minmax(90px, 1fr) minmax(95px, 1fr) minmax(105px, 1fr);`,
  Header: ``,
  Body: ``,
  BaseRow: `
        background-color: var(--theme-ui-colors-background);
    
        &.row-select-selected, &.row-select-single-selected {
          background-color: var(--theme-ui-colors-background-secondary);
          color: var(--theme-ui-colors-text);
        }
      `,
  HeaderRow: `
        font-size: 10px;
        color: var(--theme-ui-colors-text-light);
    
        .th {
          border-bottom: 1px solid var(--theme-ui-colors-border);
          border-bottom: 1px solid #a0a8ae;
        }
      `,
  Row: `
        font-size: 12px;
        color: var(--theme-ui-colors-text);
    
        &:not(:last-of-type) .td {
          border-bottom: 1px solid var(--theme-ui-colors-border);
        }
    
        &:hover {
          color: var(--theme-ui-colors-text-light);
        }
      `,
  BaseCell: `
        border-bottom: 1px solid transparent;
        border-right: 1px solid transparent;
    
        margin: 9px;
        padding: 11px;
        height: 52px;
    
        svg {
          fill: var(--theme-ui-colors-text);
        }
      `,
  HeaderCell: ``,
  Cell: `
  &:not(:last-of-type) {
    border-right: 1px solid #a0a8ae;
  }
  `,
};

/**
 * SoraDashboard component.
 * Renders a dashboard for managing folders and files in the Sora application.
 */
const SoraDashboard = () => {
  // State variables
  const [search, setSearch] = useState(""); // State for search query
  const [searchTake, setSearchTake] = useState(""); // State for take answer search query
  const navigate = useNavigate(); // Navigation function from React Router
  const url = process.env.REACT_APP_API_URL; // API base URL
  const [nodes, setNodes] = useState([]); // State for folder structure nodes
  const theme = useTheme(THEME); // Theme context hook
  const [selectedValue, setSelectedValue] = useState(""); // State for selected value
  const [organisations, setOrganisations] = useState([]); // State for user organisations

  /**
   * Builds nested nodes from the given data based on the parent-child relationship.
   * @param {Array} data - The data array containing the nodes.
   * @param {string|null} parentId - The ID of the parent node. Defaults to null for top-level nodes.
   * @returns {Array} - The array of nested nodes.
   */
  function buildNestedNodes(data, parentId = null) {
    const result = [];
    for (const item of data) {
      if (item.parent_id === parentId) {
        const children = buildNestedNodes(data, item.id);
        if (children.length > 0) {
          item.nodes = children;
        }
        result.push(item);
      }
    }
    return result;
  }

  // Memoizes the nested nodes to avoid unnecessary computations on re-renders.
  const nestedNodes = useMemo(() => buildNestedNodes(nodes, null), [nodes]);

  // Manages state for the filtered data based on search criteria.
  const [data, setData] = useState({
    nodes: filterNodes(nestedNodes, search),
  });

  // Runs once on component mount to fetch folder data based on user or organization.
  useEffect(() => {
    // Fetch organisations and folder data based on user or organization
    // Retrieve and set selected organisation ID if previously selected
    const savedOrgId = JSON.parse(localStorage.getItem("selectedOrgId"));
    if (savedOrgId !== null) {
      setSelectedValue(savedOrgId);
    }
    // Fetch organisations for the user and set the initial folder data
    fetchOrgansiationsUser().then(() => {
      localStorage.setItem("file_id", JSON.stringify(0));
      if (
        JSON.parse(localStorage.getItem("organisation_id")) === 0 ||
        JSON.parse(localStorage.getItem("organisation_id")) === null
      ) {
        getFolderByUser();
      } else {
        getFolderByOrganisation();
      }
    });
  }, []);

  // Updates the filtered data when search criteria or nested nodes change.
  useEffect(() => {
    setData({
      nodes: filterNodes(nestedNodes, search),
    });
  }, [search, nestedNodes]);

  /**
   * Retrieves the folder data for a specific user.
   * @returns {Promise<void>} A promise that resolves when the folder data is fetched and processed.
   */
  const getFolderByUser = async () => {
    try {
      const response = await fetch(
        `${url}get_folder_user?user_id=${JSON.parse(
          localStorage.getItem("user_id")
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setNodes(data);

      // Build nested nodes and update the data state
      const nestedNodes = buildNestedNodes(data, null);
      setData({
        nodes: filterNodes(nestedNodes, search),
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Retrieves the folder by organisation ID from the server.
   * @returns {Promise<void>} A promise that resolves when the folder data is fetched and processed.
   */
  const getFolderByOrganisation = async () => {
    try {
      const response = await fetch(
        `${url}get_folder_organisation?organisation_id=${JSON.parse(
          localStorage.getItem("organisation_id")
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      // Build nested nodes and update the data state
      const nestedNodes = buildNestedNodes(data, null);
      setData({
        nodes: filterNodes(nestedNodes, search),
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Generates a PDF for the given item.
   * @param {Object} item - The item for which to generate the PDF.
   * @returns {Promise<void>} - A promise that resolves when the PDF generation is complete.
   */
  async function generatePDF(item) {
    let version = "2_0";
    if (item.sora_version === "2.5") {
      version = "2_5";
    }
    try {
      const response = await fetch(`${url}generate-pdf${version}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sora_analysis_id: item.take_analysis_id,
          user_id: JSON.parse(localStorage.getItem("user_id")),
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const pdfBlob = await response.blob();

      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(pdfBlob);
      downloadLink.download = "SORA.pdf";
      downloadLink.click();
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }

  /**
   * Fetches the organisations for a user.
   * @async
   * @function fetchOrgansiationsUser
   * @returns {Promise<void>} A promise that resolves when the organisations are fetched.
   * @throws {Error} If there is an HTTP error during the fetch.
   */
  const fetchOrgansiationsUser = async () => {
    const user_id = JSON.parse(localStorage.getItem("user_id"));
    try {
      const response = await fetch(`${url}organisation?user_id=${user_id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setOrganisations(data);
    } catch (error) {
      console.error("Error fetching steps:", error);
      // Handle the error as needed.
    }
  };

  /**
   * Handles the change event when choosing an organization.
   * @param {Event} event - The change event object.
   */
  const handleChooseOrganisation = (event) => {
    const selectedOrgId = parseInt(event.target.value);
    setSelectedValue(selectedOrgId);

    // Fetch folder data based on the selected organization
    if (selectedOrgId === "no" || selectedOrgId === 0) {
      // Handle case where no organization is selected
      getFolderByUser();
      localStorage.setItem("organisation_id", JSON.stringify(0));
      localStorage.setItem("selectedOrgId", JSON.stringify(0));
    } else {
      // Fetch folder data based on the selected organization
      localStorage.setItem("organisation_id", JSON.stringify(selectedOrgId));
      localStorage.setItem("selectedOrgId", JSON.stringify(selectedOrgId));
      getFolderByOrganisation();
    }
  };

  /**
   * Handles the search event.
   * @param {Object} event - The event object.
   */
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  /**
   * Custom hook for sorting an array of data.
   *
   * @param {Array} data - The array of data to be sorted.
   * @param {Object} options - The options for sorting.
   * @param {Function} options.onChange - The callback function to be called when the sorting changes.
   * @param {Object} options.sortFns - The custom sort functions for different sorting criteria.
   * @param {Object} options.sortIcon - The icons for displaying the sorting state.
   * @returns {Object} - The sort object with sorting functionality.
   */
  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortFns: {
        NAME: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
        CREATED_AT: (array) =>
          array.sort(
            (a, b) =>
              new Date(a.created_at).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }) -
              new Date(b.created_at).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
          ),
        UPDATED_AT: (array) =>
          array.sort(
            (a, b) =>
              new Date(a.updated_at).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }) -
              new Date(b.updated_at).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
          ),
        VERSION: (array) =>
          array.sort((a, b) => a.sora_version - b.sora_version),
      },

      sortIcon: {
        margin: "4px",
        iconDefault: <UnfoldMoreOutlinedIcon fontSize="small" />,
        iconUp: <KeyboardArrowUpOutlinedIcon fontSize="small" />,
        iconDown: <KeyboardArrowDownOutlinedIcon fontSize="small" />,
      },
    }
  );

  /**
   * Represents the selected rows in the table.
   *
   * @type {object}
   * @property {array} selectedRows - The array of selected rows.
   * @property {function} toggleRowSelected - A function to toggle the selected state of a row.
   * @property {function} toggleAllRowsSelected - A function to toggle the selected state of all rows.
   */
  const select = useRowSelect(data, {
    onChange: onSelectChange,
  });

  /**
   * Represents the tree object.
   * @type {Object}
   */
  const tree = useTree(
    data,
    {
      onChange: onTreeChange,
    },
    {
      // treeIcon: {
      //   margin: "4px",
      //   iconDefault: <InsertDriveFileOutlinedIcon fontSize="small" />,
      //   iconRight: <FolderIcon fontSize="small" />,
      //   iconDown: <FolderOpenIcon fontSize="small" />,
      // },

      treeYLevel: 0,
    }
  );

  function onSortChange(action, state) {
    // console.log(action, state);
  }

  /**
   * Handles the change event of the selection.
   * @param {Object} action - The action object.
   * @param {Object} state - The state object.
   */
  function onSelectChange(action, state) {
    if (action.type === "REMOVE_BY_ID_EXCLUSIVELY") {
      localStorage.setItem("file_id", JSON.stringify(0));
    }
    if (action.type === "ADD_BY_ID_EXCLUSIVELY") {
      localStorage.setItem("file_id", JSON.stringify(state.id));
    }
    // console.log(action, state);
  }

  function onTreeChange(action, state) {
    // console.log(action, state);
  }

  function handleCellClick(item) {
    console.log("Cell Click", item);
  }

  function editName(node, event) {
    // console.log("Edit Name", node);
  }

  /**
   * Deletes an item.
   * @param {Object} item - The item to be deleted.
   */
  function deleteItem(item) {
    if (item.type === "sora") {
      deleteSora(item);
      return;
    } else {
      deleteFolder(item);
    }
  }

  /**
   * Deletes a folder.
   * @param {Object} item - The folder item to be deleted.
   * @returns {Promise<void>} - A promise that resolves when the folder is deleted successfully.
   * @throws {Error} - If there is an HTTP error during the deletion process.
   */
  async function deleteFolder(item) {
    try {
      const response = await fetch(`${url}delete_folder`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          folder_id: item.id,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else if (response.ok) {
        if (
          JSON.parse(localStorage.getItem("organisation_id")) === 0 ||
          JSON.parse(localStorage.getItem("organisation_id")) === null
        ) {
          await getFolderByUser();
        } else {
          await getFolderByOrganisation();
        }
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
    }
  }

  /**
   * Asynchronously searches for take answers associated with the current user.
   * @param {string} value - The search query.
   */
  async function searchTakeAnswer(value) {
    console.log("user"); // Logging a message indicating user search
    try {
      const response = await fetch(`${url}search_take_answer`, {
        method: "POST", // HTTP POST method
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: JSON.parse(localStorage.getItem("user_id")), // User ID retrieved from localStorage
          search: value, // Search query
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Throw an error if response is not OK
      }

      const data = await response.json(); // Parse response JSON
      setNodes(data); // Set nodes state with fetched data
      const nestedNodes = buildNestedNodes(data, null); // Build nested nodes from fetched data
      setData({
        nodes: filterNodes(nestedNodes, search), // Set data state with filtered nodes
      });
    } catch (error) {
      console.error("Error searching take answer:", error); // Log error if search fails
    }
  }

  /**
   * Asynchronously searches for take answers associated with the current organisation.
   * @param {string} value - The search query.
   */
  async function searchTakeAnswerOrganisation(value) {
    try {
      const response = await fetch(`${url}search_take_answer`, {
        method: "POST", // HTTP POST method
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          organisation_id: JSON.parse(localStorage.getItem("organisation_id")), // Organisation ID retrieved from localStorage
          search: value, // Search query
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Throw an error if response is not OK
      }

      const data = await response.json(); // Parse response JSON
      setNodes(data); // Set nodes state with fetched data
      const nestedNodes = buildNestedNodes(data, null); // Build nested nodes from fetched data
      setData({
        nodes: filterNodes(nestedNodes, search), // Set data state with filtered nodes
      });
    } catch (error) {
      console.error("Error searching take answer:", error); // Log error if search fails
    }
  }

  /**
   * Asynchronously deletes a Sora item.
   * @param {Object} item - The Sora item to be deleted.
   */
  async function deleteSora(item) {
    try {
      const response = await fetch(`${url}delete_sora`, {
        method: "DELETE", // HTTP DELETE method
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          folder_id: item.id, // ID of the folder associated with the Sora item
          take_analysis_id: item.take_analysis_id, // ID of the take analysis associated with the Sora item
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Throw an error if response is not OK
      } else if (response.ok) {
        // If deletion is successful and user is either not associated with any organisation or organisation ID is null
        if (
          JSON.parse(localStorage.getItem("organisation_id")) === 0 ||
          JSON.parse(localStorage.getItem("organisation_id")) === null
        ) {
          await getFolderByUser(); // Get folders associated with the current user
        } else {
          await getFolderByOrganisation(); // Get folders associated with the current organisation
        }
      }
    } catch (error) {
      console.error("Error deleting sora:", error); // Log error if deletion fails
    }
  }

  /**
   * View an item and store its details in local storage.
   * @param {Object} item - The item to be viewed.
   */
  function viewItem(item) {
    localStorage.setItem("file_id", JSON.stringify(item.id));
    localStorage.setItem("sora_version", JSON.stringify(item.sora_version));
    localStorage.setItem(
      "sora_analysis_id",
      JSON.stringify(item.take_analysis_id)
    );
    navigate(`/sora_step0`);
  }

  /**
   * Prints the given item by generating a PDF.
   * @param {any} item - The item to be printed.
   */
  function printItem(item) {
    generatePDF(item);
  }

  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  /**
   * Handles the form submission for creating a folder.
   * @param {Event} event - The form submission event.
   * @returns {Promise<void>} - A promise that resolves when the folder creation is complete.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (value === "" || value === null || value.trim() === ""){
      alert("Please enter a folder name.");
      return;
    }
    let selected_id = JSON.parse(localStorage.getItem("file_id"));
    let organisation_id = JSON.parse(localStorage.getItem("organisation_id"));
    // If the user is not associated with any organization
    if (organisation_id === 0 || organisation_id === null) {
      // If no folder is selected
      if (selected_id === 0) {
        try {
          // Create folder with null parent ID for top-level folder
          const response = await fetch(`${url}create_folder`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              parent_id: null,
              name: value,
              user_id: JSON.parse(localStorage.getItem("user_id")),
              type: "folder",
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          } else if (response.ok) {
            await getFolderByUser();
            return;
          }
        } catch (error) {
          console.error("Error creating folder:", error);
        }
      } else {
        try {
          // Iterate over nodes to find selected folder or sora
          for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id == selected_id && nodes[i].type === "folder") {
              // Create folder with selected folder as parent
              const response = await fetch(`${url}create_folder`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  parent_id: selected_id,
                  name: value,
                  user_id: JSON.parse(localStorage.getItem("user_id")),
                  type: "folder",
                }),
              });

              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              } else if (response.ok) {
                await getFolderByUser();
              }
            } else if (nodes[i].id == selected_id && nodes[i].type === "sora") {
              // Create folder with parent of selected sora
              const response = await fetch(`${url}create_folder`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  parent_id: nodes[i].parent_id,
                  name: value,
                  user_id: JSON.parse(localStorage.getItem("user_id")),
                  type: "folder",
                }),
              });

              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              } else if (response.ok) {
                await getFolderByUser();
              }
            }
          }
          // Call getSora after the POST requests are completed
          // await getSora();
        } catch (error) {
          console.error("Error creating folder:", error);
        }
      }
    }
    // If the user is associated with an organization
    else {
      // If no folder is selected
      if (selected_id === 0) {
        try {
          // Create folder with null parent ID for top-level folder
          const response = await fetch(`${url}create_folder_organisation`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              parent_id: null,
              name: value,
              organisation_id: organisation_id,
              type: "folder",
            }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          } else if (response.ok) {
            await getFolderByOrganisation();
            return;
          }
        } catch (error) {
          console.error("Error creating folder:", error);
        }
      } else {
        try {
          // Iterate over nodes to find selected folder or sora
          for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id == selected_id && nodes[i].type === "folder") {
              // Create folder with selected folder as parent
              const response = await fetch(`${url}create_folder_organisation`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  parent_id: selected_id,
                  name: value,
                  organisation_id: JSON.parse(
                    localStorage.getItem("organisation_id")
                  ),
                  type: "folder",
                }),
              });

              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              } else if (response.ok) {
                await getFolderByOrganisation();
              }
            } else if (nodes[i].id == selected_id && nodes[i].type === "sora") {
              // Create folder with parent of selected sora
              const response = await fetch(`${url}create_folder_organisation`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  parent_id: nodes[i].parent_id,
                  name: value,
                  organisation_id: JSON.parse(
                    localStorage.getItem("organisation_id")
                  ),
                  type: "folder",
                }),
              });

              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              } else if (response.ok) {
                await getFolderByOrganisation();
              }
            }
          }
          // Call getSora after the POST requests are completed
          // await getSora();
        } catch (error) {
          console.error("Error creating folder:", error);
        }
      }
    }
  };

  /**
   * Handles the submission of take answer search.
   * @param {Event} event - The form submission event.
   */
  const handleTakeAnswer = async (event) => {
    event.preventDefault();
    setSearchTake(event.target.value);
    if (event.target.value.length > 0) {
      // If the user is not associated with any organization
      if (
        JSON.parse(localStorage.getItem("organisation_id")) === 0 ||
        JSON.parse(localStorage.getItem("organisation_id")) === null
      ) {
        // Search for take answer based on user
        searchTakeAnswer(event.target.value);
      } else {
        // Search for take answer based on organization
        searchTakeAnswerOrganisation(event.target.value);
      }
    } else {
      // If search input is empty
      if (
        JSON.parse(localStorage.getItem("organisation_id")) === 0 ||
        JSON.parse(localStorage.getItem("organisation_id")) === null
      ) {
        // Get folder data based on user
        getFolderByUser();
      } else {
        // Get folder data based on organization
        getFolderByOrganisation();
      }
    }
  };

  return (
    /**
     * Renders the main dashboard content including folder creation form, search inputs, and data table.
     */
    <Container>
      {/* Adds some space */}
      <br />

      {/* Displays a heading */}
      <h1 style={{ textAlign: "center" }}>Sora Dashboard</h1>

      {/* Adds some space */}
      <br />

      {/* Heading to choose organisation */}
      <h5>Choose Organisation</h5>
      {/* Dropdown to select organisation */}
      <Form.Select
        aria-label="Default select example"
        onChange={handleChooseOrganisation}
        value={selectedValue}
      >
        {/* Option for no organisation */}
        <option key={0} value={0}>
          No organisation
        </option>
        {/* Mapping over organisation options */}
        {organisations.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>

      <br />

      {/* Form for search and creating a new folder */}
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* Search input */}
        <label htmlFor="search" style={{ marginRight: "10px" }}>
          Search by Name:&nbsp;
          <input
            id="search"
            type="text"
            value={search}
            onChange={handleSearch}
          />
        </label>

        {/* Form for creating a new folder */}
        <form onSubmit={handleSubmit} style={{ marginRight: "10px" }}>
          <label htmlFor="folder_name">
            Create New Folder:&nbsp;
            <input
              type="text"
              id="type-folder"
              value={value}
              onChange={handleChange}
            />
            {/* Button to create a new folder */}
            <Button
              id="create-folder-button"
              variant="info"
              type="submit"
              size="sm"
              style={{ marginLeft: "3px", verticalAlign: "middle" }}
            >
              Create
            </Button>
          </label>
        </form>

        {/* Button to navigate to create a new Sora */}
        <div style={{ marginLeft: "auto" }}>
          <Button
            id="new-sora-button"
            className="create_sora"
            onClick={() => navigate(`/sora_start`)}
          >
            Create New Sora
          </Button>
        </div>
      </div>

      {/* Adds some space */}
      <br />

      {/* Search input for folder answer */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <label htmlFor="search_take_answer" style={{ marginRight: "10px" }}>
          search for folder answer:&nbsp;
          <input
            id="search_take_answer"
            type="text"
            value={searchTake}
            onChange={handleTakeAnswer}
          />
        </label>
      </div>

      {/* Renders a table with data */}
      <Table
        data={data}
        theme={theme}
        sort={sort}
        select={select}
        layout={{ custom: true, horizontalScroll: true }}
        tree={tree}
      >
        {/* Renders the table header */}
        {(tableList) => (
          <>
            <Header>
              <HeaderRow>
                {/* Header cells for different attributes */}
                <HeaderCellSort resize sortKey="NAME">
                  Name
                </HeaderCellSort>
                <HeaderCellSort resize sortKey="CREATED_AT">
                  CREATED_AT
                </HeaderCellSort>
                <HeaderCellSort resize sortKey="UPDATED_AT">
                  UPDATED_AT
                </HeaderCellSort>
                <HeaderCellSort resize sortKey="VERSION">
                  Version
                </HeaderCellSort>
                {/* Actions header cells */}
                <HeaderCellSort>
                  <span>Edit</span>
                </HeaderCellSort>
                <HeaderCellSort>
                  <span>Print</span>
                </HeaderCellSort>
                <HeaderCellSort>
                  <span>Delete</span>
                </HeaderCellSort>
              </HeaderRow>
            </Header>

            {/* Renders the table body */}
            <Body>
              {/* Maps over the tableList to render each row */}
              {tableList.map((item) => (
                <Row
                  key={item.id}
                  item={item}
                  // Double-click handler for editing name
                  onDoubleClick={(node, event) => editName(node, event)}
                >
                  {/* Renders cells for each attribute */}
                  <CellTree item={item}>
                    {item.type === "folder" ? (
                      <FolderIcon fontSize="small" />
                    ) : (
                      <InsertDriveFileOutlinedIcon fontSize="small" />
                    )}
                    <span data-testid={`${item.name}`}>{item.name}</span>
                  </CellTree>

                  <Cell>
                    {/* Formats the created_at date */}
                    {new Date(item.created_at).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </Cell>
                  <Cell>
                    {/* Formats the updated_at date */}
                    {new Date(item.updated_at).toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </Cell>
                  <Cell>{item.sora_version}</Cell>
                  {/* Actions cells */}
                  <Cell>
                    {/* Button to edit if the item is a sora */}
                    {item.type === "sora" && (
                      <Button
                        id={`edit-${item.name}`}
                        onClick={() => viewItem(item)}
                      >
                        Edit
                      </Button>
                    )}
                  </Cell>
                  <Cell>
                    {/* Button to print if the item is a sora */}
                    {item.type === "sora" && (
                      <Button onClick={() => printItem(item)}>Print</Button>
                    )}
                  </Cell>
                  <Cell>
                    {/* Button to delete the item  */}
                    <Button onClick={() => deleteItem(item)}>Delete</Button>
                  </Cell>
                </Row>
              ))}
            </Body>
          </>
        )}
      </Table>
    </Container>
  );
};

/**
 * Filters an array of nodes based on a search string.
 * @param {Array} nodes - The array of nodes to filter.
 * @param {string} search - The search string to match against node names.
 * @returns {Array} - The filtered array of nodes.
 */
function filterNodes(nodes, search) {
  return nodes.reduce((acc, node) => {
    const filteredNode = { ...node };

    if (node.nodes) {
      filteredNode.nodes = filterNodes(node.nodes, search);
    }

    if (
      filteredNode.name.toLowerCase().includes(search.toLowerCase()) ||
      (filteredNode.nodes && filteredNode.nodes.length > 0)
    ) {
      acc.push(filteredNode);
    }

    return acc;
  }, []);
}

export default SoraDashboard;
